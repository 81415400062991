import styled, { css } from 'styled-components';
import colors from 'style/palette';

export const ProductSubscriptionBox = styled.div<{ isService: boolean; isDeleted: boolean; isExpired: boolean; isServiceAutoRenew: boolean; isClickable?: boolean; isPayedInPoints?: boolean }>`
	font-size: 0.7rem;
	padding: 0.2rem;
	border-radius: 0.2rem;
	border-style: ${({ isPayedInPoints }) => (isPayedInPoints ? 'solid' : 'solid 1px #000000')};
	background-color: ${colors.green};
	cursor: ${({isClickable}) => (isClickable ? 'pointer' : 'default')};

	${({ isClickable }) => isClickable &&
		css`
			:hover {
				background-color: ${colors.veryDarkGrey};
				color: ${colors.white};
			}
		`}

	${({ isService, isExpired, isServiceAutoRenew }) =>
		isService &&
		!isExpired &&
		!isServiceAutoRenew &&
		css`
			background-color: ${colors.primaryColor};
			color: ${colors.black};
		`}

	${({ isExpired }) =>
		isExpired &&
		css`
			background-color: ${colors.red};
			color: ${colors.white};
		`}

	${({ isDeleted }) =>
		isDeleted &&
		css`
			background-color: ${colors.veryDarkGrey};
			color: ${colors.white};
			text-decoration: line-through;
		`}

`;
